var exports = {};
var get = easyGet;
var set = easySet;

if (document.selection && document.selection.createRange) {
  get = hardGet;
  set = hardSet;
}

function easyGet(el) {
  return {
    start: el.selectionStart,
    end: el.selectionEnd
  };
}

function hardGet(el) {
  var active = document.activeElement;

  if (active !== el) {
    el.focus();
  }

  var range = document.selection.createRange();
  var bookmark = range.getBookmark();
  var original = el.value;
  var marker = getUniqueMarker(original);
  var parent = range.parentElement();

  if (parent === null || !inputs(parent)) {
    return result(0, 0);
  }

  range.text = marker + range.text + marker;
  var contents = el.value;
  el.value = original;
  range.moveToBookmark(bookmark);
  range.select();
  return result(contents.indexOf(marker), contents.lastIndexOf(marker) - marker.length);

  function result(start, end) {
    if (active !== el) {
      // don't disrupt pre-existing state
      if (active) {
        active.focus();
      } else {
        el.blur();
      }
    }

    return {
      start: start,
      end: end
    };
  }
}

function getUniqueMarker(contents) {
  var marker;

  do {
    marker = "@@marker." + Math.random() * new Date();
  } while (contents.indexOf(marker) !== -1);

  return marker;
}

function inputs(el) {
  return el.tagName === "INPUT" && el.type === "text" || el.tagName === "TEXTAREA";
}

function easySet(el, p) {
  el.selectionStart = parse(el, p.start);
  el.selectionEnd = parse(el, p.end);
}

function hardSet(el, p) {
  var range = el.createTextRange();

  if (p.start === "end" && p.end === "end") {
    range.collapse(false);
    range.select();
  } else {
    range.collapse(true);
    range.moveEnd("character", parse(el, p.end));
    range.moveStart("character", parse(el, p.start));
    range.select();
  }
}

function parse(el, value) {
  return value === "end" ? el.value.length : value || 0;
}

function sell(el, p) {
  if (arguments.length === 2) {
    set(el, p);
  }

  return get(el);
}

exports = sell;
export default exports;